<pg-layout-home>
  <main>
    <div class="edu-header">
      <section class="container">
        <div class="edu-header-text">Educators</div>
      </section>
    </div>
    <div class="edu-video-section">
      <section class="container text-center bold-section">
        <h1 style="font-weight: 900" class="display-4 text-primary">Teachers Love Plasma Games</h1>
        <div class="row mt-4">
          <div class="col-lg-7 mb-4">
            <div class="edu-video">
              <img class="img-fluid rounded" src="assets/img/educators/photo_class_teaching_crop.png" alt="" />
            </div>
          </div>
          <div class="col-lg-5 mb-4 edu-video-text">
            <div class="text-dark text-center">
              Teachers love how Plasma helps them make lasting impacts on their students’ lives. Plasma is easy to implement and saves teachers time. Equipping teachers with
              effective tools like Plasma increases student outcomes, teacher satisfaction, and teacher retention. Click the button below to start powering up your district!
            </div>
            <a routerLink="/contact" class="btn btn-secondary fw-700 px-5 text-lg"> Contact Us </a>
          </div>
        </div>
      </section>
    </div>

    <div class="sci-ops-section bold-section">
      <h1 class="display-4 mb-4 text-white text-center">Why is Sci-Ops so Unique?</h1>
      <div class="container edu-card-container">
        <div class="card" data-aos="fade-right" data-aos-delay="100">
          <div class="card-body sci-ops-card row">
            <div class="col-lg-8">
              <img class="sci-ops-img-fluid" src="assets/img/educators/gameplay.png" alt="" />
            </div>
            <div class="col-lg-4">
              <div class="sci-ops-card-text">
                <h1 class="text-secondary">Fuses Gameplay & Science Standards</h1>
                <p class="px-5 text-dark">
                  Students interact with standards aligned graphs, simulations, and models inside a 3D game as they use their gear to save our planet from the HIVE.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card" data-aos="fade-left" data-aos-delay="100">
          <div class="card-body sci-ops-card row">
            <div class="col-lg-4">
              <div class="sci-ops-card-text">
                <h1 class="text-secondary">Application of Real World Technologies</h1>
                <p class="px-5 text-dark">
                  Students apply science standards to real world technologies as they upgrade their gear in the lab, which provides purpose and meaning to their learning.
                </p>
              </div>
            </div>
            <div class="col-lg-8"><img class="sci-ops-img-fluid" src="assets/img/educators/tech.png" alt="" /></div>
          </div>
        </div>
        <div class="card" data-aos="fade-right" data-aos-delay="100">
          <div class="card-body sci-ops-card row">
            <div class="col-lg-8"><img class="sci-ops-img-fluid" src="assets/img/educators/career.png" alt="" /></div>
            <div class="col-lg-4">
              <div class="sci-ops-card-text">
                <h1 class="text-secondary">In-Demand STEM Careers</h1>
                <p class="px-5 text-dark">
                  Students explore high paying STEM careers (which include community college, undergraduate, and graduate level tracks) as they rescue captured experts.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card" data-aos="fade-left" data-aos-delay="100">
          <div class="card-body sci-ops-card row">
            <div class="col-lg-4">
              <div class="sci-ops-card-text-video h-100">
                <h1 class="text-primary text-center" style="font-size: 3rem; font-weight: 700">Check it out for yourself!</h1>
              </div>
            </div>
            <div class="col-lg-8">
              <iframe
                class="sci-ops-vid-fluid"
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/KFJGt3nzZ24"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="edu-access-section bold-section">
      <div class="container">
        <h1 class="display-4 mb-4 text-secondary text-center">What else will I have access to?</h1>
        <div class="edu-access-grid text-center text-dark">
          <div class="card bg-white" data-aos="fade-up">
            <div class="card-body edu-access-card bold-section">
              <div class="access-icon text-primary">
                <fa-icon class="fa-4x" [icon]="['fas', 'users-class']"></fa-icon>
              </div>
              <h1 class="text-primary">Teacher Portal</h1>
              <p>Track your students' progress, access helpful resources, and receive teacher support on your teacher portal.</p>
            </div>
          </div>
          <div class="card bg-white" data-aos="fade-up">
            <div class="card-body edu-access-card bold-section">
              <div class="access-icon text-primary">
                <fa-icon class="fa-4x" [icon]="['fas', 'pencil-alt']"></fa-icon>
              </div>
              <h1 class="text-primary">Student Activities</h1>
              <p>Reinforce and assess your students' understanding of your state's grade-level standards with our engaging student activities!</p>
            </div>
          </div>
          <div class="card bg-white" data-aos="fade-up">
            <div class="card-body edu-access-card bold-section">
              <div class="access-icon text-primary">
                <fa-icon class="fa-4x" [icon]="['fas', 'hiking']"></fa-icon>
              </div>
              <h1 class="text-primary">Pacing Guides</h1>
              <p>Access our pacing guides that outline and provide tips on how to successfully integrate Sci-Ops and our student activities into your classroom for each unit.</p>
            </div>
          </div>
          <div class="card bg-white" data-aos="fade-up">
            <div class="card-body edu-access-card bold-section">
              <div class="access-icon text-primary">
                <fa-icon class="fa-4x" [icon]="['fas', 'file-certificate']"></fa-icon>
              </div>
              <h1 class="text-primary">Standards Alignment</h1>
              <p>View how our resources, game, and activities address your state's standards.</p>
            </div>
          </div>
          <div class="card bg-white" data-aos="fade-up">
            <div class="card-body edu-access-card bold-section">
              <div class="access-icon text-primary">
                <fa-icon class="fa-4x" [icon]="['fas', 'user-tie']"></fa-icon>
              </div>
              <h1 class="text-primary">Professional Development</h1>
              <p>Attend a training or a coaching session to receive professional development and get the most out of our resources!</p>
            </div>
          </div>
          <div class="card bg-white" data-aos="fade-up">
            <div class="card-body edu-access-card bold-section">
              <div class="access-icon text-primary">
                <fa-icon class="fa-4x" [icon]="['fas', 'clock']"></fa-icon>
              </div>
              <h1 class="text-primary">24/7 Support</h1>
              <p>
                Receive support from our customer support team, who are available to help in any way you need! Also, gain access to tutorial videos, and helpful teacher tips in
                your teacher portal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</pg-layout-home>
