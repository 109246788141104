<pg-layout-home>
  <main class="">
    <div class="header">
      <section class="container">
        <div class="header-text">Careers</div>
      </section>
    </div>
    <div class="bg-white pt-4 mb-4">
      <section class="container text-center bold-section">
        <h1 style="font-weight: 800;" class="display-4 text-primary mb-4">JOIN OUR TEAM</h1>
        <div class="row mb-4">
          <div class="col-lg-3 col-md-2"></div>
          <div class="col">
            <div class="row">
              <div class="col">
                <img class="img-fluid rounded-circle" src="/assets/img/team/meeting.png" alt="">
              </div>
              <div class="col">
                <img class="img-fluid rounded-circle" src="/assets/img/team/award.png" alt="">
              </div>
              <div class="col">
                <img class="img-fluid rounded-circle" src="/assets/img/team/conference.png" alt="">
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-2"></div>
        </div>
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col">
            <p class="text-center mx-4">
              At Plasma Games, talented individuals come together to change life trajectories. We equip teachers with rigorous, entertainment quality
              resources
              that allow them to engage students, improve learning, and inspire our next generation of STEM leaders. Plasma aims not only to change the life trajectories of
              teachers
              and students but of their employees as well. Apply now to join our team!
            </p>
          </div>
          <div class="col-md-1"></div>
        </div>
      </section>
    </div>
    <div class="container py-4">
      <pg-career-cards></pg-career-cards>
    </div>
  </main>
</pg-layout-home>