import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoggedInUser } from '@modules/auth/store';
import { Store } from '@ngxs/store';

@Component({
  selector: 'pg-secret-webpage',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './secret-webpage.component.html',
  styleUrls: ['secret-webpage.component.scss'],
})
export class SecretWebpageComponent implements OnInit {
  public isMenuCollapsed = true;
  user: LoggedInUser;
  constructor(private store: Store) {}
  ngOnInit() {
    this.user = this.store.selectSnapshot<LoggedInUser>((state) => state.loggedInUser.user);
  }
}
