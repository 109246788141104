import { MainComponent } from './main/main.component';
import { EducatorsComponent } from './educators/educators.component';
import { AboutComponent } from './about/about.component';
import { LegalComponent } from './legal/legal.component';
import { SecretWebpageComponent } from './secret-webpage/secret-webpage.component';
import { SecretGearGuidesComponent } from './secret-gear-guides/secret-gear-guides.component';
import { CareersComponent } from './careers/careers.component';

export const containers = [MainComponent, EducatorsComponent, AboutComponent, LegalComponent, SecretWebpageComponent, SecretGearGuidesComponent, CareersComponent];

export * from './main/main.component';
export * from './educators/educators.component';
export * from './about/about.component';
export * from './legal/legal.component';
export * from './secret-webpage/secret-webpage.component';
export * from './secret-gear-guides/secret-gear-guides.component';
export * from './careers/careers.component';
