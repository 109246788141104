<pg-layout-home>
  <main>
    <div class="header mb-4">
      <section class="container">
        <div class="header-text">Legal</div>
      </section>
    </div>
    <div class="container">
      <iframe class="rounded" src="https://dkmzd4tgwtjws.cloudfront.net/public/files/PlasmaGamesEUALAv2.020191003.pdf" width="100%" height="700px" allow="autoplay"></iframe>
    </div>
  </main>
</pg-layout-home>