import { PlasmaTeamMember } from '../models/teamMember.model';

export const PlasmaOfficers: PlasmaTeamMember[] = [
  {
    name: 'Hunter Moore',
    title: 'Chief Executive Officer',
    info: `Nanoresearch at Sandia National Labs, jet engine research at Pratt and Whitney, aircraft manufacturing at Boeing, and software at IBM. BS & MS in mechanical engineering from Georgia Institute of Technology focused in robotics and microelectromechanical systems as well as an MBA from UNC Chapel Hill. I have been involved in various STEM education programs from 3rd grade to graduate level.`,
    funFact: 'Most likely to give you a hug',
    imgUrl: '/assets/img/about/illustration/hunter.png',
  },
  {
    name: 'Rose Roberts',
    title: 'Chief Operating Officer',
    info: `Education is her PASSION! Rose has taken her experience as a math and science teacher and her studies on curriculum and instruction to lead our team at Plasma to create educational resources she wished she had. When she is not working (or tripping over things) Rose enjoys spending time with her family, kickboxing, and trying new restaurants.`,
    funFact: 'Most likely to “whisper” and be heard by the hostess at the front of the restaurant.',
    imgUrl: '/assets/img/about/illustration/rose.png',
  },
  {
    name: 'Annie Moore',
    title: 'Chief Financial Officer',
    info: `Annie leads all the financial components at Plasma Games. She is a certified public accountant with 6 years public accounting and 6 years private account experience. She holds a bachelor and a master in accounting from Samford University.`,
    funFact: 'Most likely to do a marathon without training.',
    imgUrl: '/assets/img/about/illustration/annie.png',
  },
];

export const PlasmaTeamMembers: PlasmaTeamMember[] = [
  {
    name: 'Catherine Levert',
    title: 'Creative Lead',
    info:
      'Catherine turned her passion for making things both beautiful and functional into her current role as Creative Lead at Plasma. She appreciates the creative and collaborative process and working with incredible people! When she’s not at her computer, you’ll find her climbing mountains, reading, or playing video games on some vintage game system!',
    funFact: 'Most likely to break the exclamation point button on their keyboard!!!!!!',
    imgUrl: '/assets/img/about/illustration/catherine.png',
  },
  {
    name: 'Carter Popson',
    title: 'Full Stack Developer',
    info:
      'Carter has always had a passion for technology and helping others through it. Working at Plasma has allowed him to use that passion to make education better for the future generation. When Carter is not working you will either find him watching a Carolina Hurricanes game or playing video games.',
    funFact: 'Most likely to know more about the Carolina Hurricanes than the coach of the Caroline Hurricanes….and fish. He knows alot about fish.',
    imgUrl: '/assets/img/about/illustration/carter.png',
  },
  {
    name: 'Zach Haymore',
    title: 'Associate Full Stack Developer',
    info:
      'Zach enjoys learning new things and helping others! He takes his passions into his role by being willing to take on any task that needs to be done to help out the team. When he’s not sending emails or creating more spreadsheets than necessary, Zach can be found playing soccer, pickleball, or reading.',
    funFact: 'Most likely to know how to do, like, literally anything.',
    imgUrl: '/assets/img/about/illustration/zach.png',
  },
  {
    name: 'Josae Neptune',
    title: 'District Relations Specialist',
    info:
      'While teaching secondary science, Josae Neptune noticed that all students have an innate curiosity for the world around them. She joined Plasma Games to combine social learning and storytelling to create engaging lessons for all learners. In between scaffolding lessons, she is either hiking with her dog, or playing a variety of video and board games.',
    funFact: 'Most likely to corner you to explain all the different types of metal music, and will find the one for you.',
    imgUrl: '/assets/img/about/illustration/josae.png',
  },
  {
    name: 'Malory Pacheco',
    title: 'Illustrator & Animator',
    info:
      'Equal parts determined, animated, and funny, Malory dances through life to the beat of the latest song stuck in her head. She is passionate about creating story driven content for young adults and is excited to be with Plasma Games creating fun and interactive content to inspire excited learning. When she’s not drawing she is often found playing video games, laughing with her roommates, or watching cartoons.',
    funFact: 'Most likely to beat you at Tetris. (Cool or sad? You decide.)',
    imgUrl: '/assets/img/about/illustration/malory.png',
  },
  {
    name: 'Zachariah Fleming',
    title: 'Multimedia Designer',
    info:
      'Creative, driven and motivated, Zachariah finds passion in all that he does. Whether that’s working on his latest big design project or sitting on the beach and doing absolutely nothing. He has truly mastered the work/life balance, but has found that both are better with company. ',
    funFact: 'Most likely to start bouncing his leg at any given moment.',
    imgUrl: '/assets/img/about/illustration/zachariah.png',
  },
  {
    name: 'Jessie Maglio',
    title: 'Customer Success Lead',
    info:
      'After navigating online-hybrid teaching, Jessie was drawn to helping teachers and students thrive in this new age of digital learning. She joined the Plasma team to use her experience as a middle grades math and science teacher, combined with her creative drive, to create engaging educational resources. Jessie is a major foodie, so outside of work you can find her at a local restaurant or trying a new recipe at home.',
    funFact: 'Most likely to catch you up on the latest reality TV drama.',
    imgUrl: '/assets/img/about/illustration/jessie.png',
  },
  {
    name: 'Gabriel Rollins',
    title: 'Customer Success Associate',
    info:
      'With over 6 years of experience mentoring and volunteering with children and teenagers, Gabriel has a desire to see youth develop into the best they can be! He is passionate about his role because he knows the impact Plasma Games has made in education and aims to communicate that impact to anyone and everyone who will listen. During his free time, you can most likely find Gabriel traveling, exploring new music (Jazz, Indie, or Neo-Soul to name a few), volunteering with kids & teens, or binge watching a TV series on Netflix.',
    funFact: 'Most likely to burst out laughing in any situation, good or bad.',
    imgUrl: '/assets/img/about/illustration/gabe.png',
  },
  {
    name: 'Kristen Holland',
    title: 'District Relations Specialist',
    info:
      'Kristen has a passion for helping teachers engage their students through using new technologies in their classrooms.  She joined the Plasma team to leverage her 16 years of experience teaching chemistry, as well as physics and physical science.  She is most excited to develop interactive and creative resources for science educators, to grow student’s passion for chemistry, and to encourage students to envision themselves in a STEM career.  Outside of work, you would probably find her attending her children’s athletic events or spending time with her friends.',
    funFact: 'Most likely to have a smile on her face and make sure you are fed!',
    imgUrl: '/assets/img/about/illustration/kristen.png',
  },
  {
    name: 'Evan Kountouris',
    title: 'Full Stack Developer',
    info:
      'Evan is laid-back and focused on getting the job done. He joined Plasma Games inspired by the mission and the amazing people on the team. Outside of work hours, Evan enjoys playing and watching soccer, going to the gym, playing video games, and going out on dates and trips with his wife.',
    funFact: 'Most likely to laugh at anything.',
    imgUrl: '/assets/img/about/illustration/evan.png',
  },
  {
    name: 'John Goodman',
    title: 'VP of Government Affairs & Business Development',
    info:
      'John provides expertise in strategic and tactical planning, business development, media relations, regulatory compliance, and public positioning. He has partnered with Fortune 100/500 executives across multiple sectors to form and lead coalitions that capitalize on grassroots campaigns, consensus-building, legislative competence, and savvy PAC management to garner bipartisan support.',
    funFact: 'In his leisure time, John enjoys spending time on the golf course, ski slope, or soccer pitch.',
    imgUrl: '/assets/img/about/illustration/john.png',
  },
  {
    name: 'Julie Cozzie',
    title: 'Curriculum & Integration Specialist',
    info:
      'Julie graduated from UNC - Chapel Hill with MSPH with an emphasis in environmental science.  After working as an environmental engineer, Julie retired to live the quiet life of a mom with four kids.  After successfully finishing that quest, Julie decided to save the world one child at a time - she obtained a masters in education and began teaching math and science.  Julie now hopes to make a bigger impact by providing teachers with tools for engaging STEM lessons.',
    funFact: 'Most likely to dance to anything.',
    imgUrl: '/assets/img/about/illustration/julie.png',
  },
  {
    name: 'Mattison Domke',
    title: 'Motion Graphic Designer',
    info:
      'Mattison has a passion for bringing ideas to life and using media as her tool. Driven by her love for learning, she is excited to contribute to the educational realm. In her spare time, she loves to play video games, read books and pamper her two cats.',
    funFact: 'Most likely to trip going up the stairs.',
    imgUrl: '/assets/img/about/illustration/mattison.png',
  },
  {
    name: 'Frankie Maraboli',
    title: 'Business Support Associate',
    info:
      "Inspired by Plasma Games unique approach to education, Frankie brings an extensive background in sales and customer support to help encourage the next generation's love of STEM careers. He loves History, Nature walks, and Playing new board games. In his free time you can catch him watching hockey, playing cards, or talking about how cool his cat is to whoever will listen!",
    funFact: 'Most likely to win a game of Clue.',
    imgUrl: '/assets/img/about/illustration/frankie.png',
  },
  {
    name: 'Gordon Gabriel Jr.',
    title: 'Customer Success Specialist',
    info:
      'Gordon, who also goes by "Junior" , brings a unique background of agricultural science to the team. As a graduate of the University of Mt Olive, he proceeded to start the middle school agricultural education program at an urban North Raleigh middle school prior to coming to Plasma. In his free time, he enjoys traveling, spending time with friends and family, trying out new restaurants, and working on his latest project - converting an old school bus into an RV. At Plasma, he is excited to continue his work in the field of education and to be able to make a positive impact in the lives of students and educators.',
    funFact: '',
    imgUrl: '/assets/img/about/illustration/junior.png',
  },
  {
    name: 'Emily Harrison Frankoff',
    title: 'Customer Success Specialist',
    info:
      'Emily, a previous teacher, brings a passion for both education and science to the team with her experience. She joined the Plasma team as an opportunity for growth and to use her background to help other educators feel successful within their classrooms. As a graduate of Appalachian State University, she loves traveling back to the mountains to visit family and friends. Outside of work you will most likely find her spending time with her husband, dog and cat and probably binge watching The Office for the millionth time.',
    funFact: '',
    imgUrl: '/assets/img/about/illustration/emily.png',
  },
  {
    name: 'Tierra Anderson',
    title: 'Associate Director of Business Development and Strategic Support',
    info:
      'Tierra graduated from North Carolina A&T State University with a B.S. in Communications and North Carolina Central University with a Masters of Public Administration. She enjoys traveling, trying new cuisines, and spending time with family and friends.',
    funFact: '',
    imgUrl: '/assets/img/about/illustration/tierra.png',
  },
  {
    name: 'Nikolas McKeever',
    title: 'Multimedia Graphic Designer',
    info:
      'Filled with strange ideas and wild dreams, Nikolas brings a flare of creativity to everything he touches. As a graduate of East Carolina University, Nikolas uses his love for art and nature as inspiration in every project, and is excited to bring that passion to Plasma Games. Outside of work you’ll find him playing video games, sketching birds, or wrestling with his pets!',
    funFact: "Most likely to be so quiet you forget he's there.",
    imgUrl: '/assets/img/about/illustration/nikolas.png',
  },
  {
    name: 'Christina Womble',
    title: 'District Relations Leader',
    info:
      'Christina has spent 14 years in public school education advocating for student success and innovative education. Now she continues her passion at Plasma Games helping districts and educators access researched-based, cutting edge resources for their students.  She is a certified Curriculum and Instructional Specialist and has a doctorate in Educational Leadership from Wingate University. Christina is a North Carolina native, enjoys spending time with her family, and traveling.',
    funFact: 'Most likely to eat dessert first.',
    imgUrl: '/assets/img/about/illustration/christina.png',
  },
];
