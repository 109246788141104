import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { get } from 'scriptjs';
import * as Phaser from 'phaser';

@Component({
  selector: 'pg-activity-secret-gear-guides',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './secret-gear-guides.component.html',
  styleUrls: ['secret-gear-guides.component.scss'],
})
export class SecretGearGuidesComponent implements OnInit, OnDestroy {
  phaserGame: Phaser.Game;

  ngOnInit() {
    // S3 LINK to the Activity's location
    const baseURL = 'https://s3.us-east-2.amazonaws.com/activities.plasma.games/GearGuides';

    // @ts-ignore
    get(baseURL + '/build/build.min.js', () => {
      // @ts-ignore
      const game = GearGuide.GameConfig(baseURL);
      this.phaserGame = new Phaser.Game(game);
    });
  }

  ngOnDestroy() {
    this.phaserGame.destroy(true);
  }
}
