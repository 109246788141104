<div class="fontLoader1">&nbsp;</div>
<div class="fontLoader2">&nbsp;</div>
<div class="fontLoader3">&nbsp;</div>
<div class="fontLoader4">&nbsp;</div>

<main>
  <div class="container-fluid mt-n5 frame">
    <div class="row mt-4">
      <div class="col-lg-3 col-md-12">
        <div class="card">
          <div class="card-body">
            <h1 class="text-center text-pg-light-blue">What are Gear Guides</h1>
            <p class="text-center">These Gear Guides were designed to help students focus in on individual pieces of gear while learning more about Sci-Ops characters!</p>
            <p class="text-center">
              The information in the guides progresses from middle grades friendly language up to a high school level, helping all students to self-differentiate as they access
              grade-level appropriate vocabulary and text. For middle school students, teachers may also find that Gear Guides are an excellent scaffolding tool to help guide your
              students into the Sci-Ops universe.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div id="game-wrapper"></div>
      </div>
    </div>
  </div>
</main>
