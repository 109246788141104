<pg-layout-home>
  <main>
    <div class="about-header">
      <section class="container">
        <div class="about-header-text">Our Mission</div>
      </section>
    </div>
    <div class="about-video-section">
      <section class="container text-center bold-section">
        <div class="about-video-text text-center text-dark">
          Plasma’s mission is to equip teachers with rigorous, entertainment quality resources that allow them to engage students, improve learning, and inspire our next generation
          of STEM leaders. The United States currently ranks 25th in the world in science and 35th in math. This is not sustainable for our economy, our national security, or our
          students’ future. Plasma is here to help get the United States back on top by strengthening our STEM workforce pipeline. Meet our hardworking team who is making our
          mission happen!
        </div>
      </section>
      <section class="container">
        <div class="article-content-grid">
          <div class="article-description article-card">
            <h2 class="text-center text-primary article-head"><span class="article-span">WRAL</span>TechWire</h2>
            <div class="text-dark article-preview">Raleigh startup is hoping to make its 3D video game part of NC's school curriculum</div>
            <a href="https://www.wraltechwire.com/2019/11/04/raleigh-startup-is-hoping-to-make-its-3d-video-game-part-of-ncs-school-curriculum/" target="_blank"><img
                   src="/assets/img/about/article.svg" alt="/" class="article-img" /></a>
          </div>
          <div class="article-description article-card">
            <h2 class="text-center text-primary article-head">GrepBeat</h2>
            <div class="text-dark article-preview">Plasma Games Makes Chemistry Fun For Students With Sci-Ops: Global Defense</div>
            <a href="https://grepbeat.com/2019/06/25/plasma-games-makes-chemistry-fun-for-students-with-sci-ops-global-defense/" target="_blank"><img
                   src="/assets/img/about/article.svg" alt="/" class="article-img" /></a>
          </div>
        </div>
      </section>
    </div>
    <div class="about-team bold-section">
      <h1 class="display-3 text-center text-primary">Meet the Team</h1>
      <div class="container about-team-grid">
        <pg-about-us-team-member [teamMember]="officer" *ngFor="let officer of plasmaOfficers"></pg-about-us-team-member>
      </div>
    </div>
    <div class="about-team bg-white">
      <div class="container about-team-grid">
        <pg-about-us-team-member [teamMember]="member" *ngFor="let member of plasmaTeam"></pg-about-us-team-member>
      </div>
    </div>
  </main>
</pg-layout-home>