<nav class="navbar navbar-expand-lg navbar-light bg-white shadow" id="sidenavAccordion">
  <a class="navbar-brand d-sm-block" target="_blank" href="/"><img src="assets/img/logo/plasma_logo_h.png" alt="Plasma Games Logo" /></a>
  <button
    class="navbar-toggler"
    type="button"
    (click)="isMenuCollapsed = !isMenuCollapsed"
    data-bs-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
    <ul class="navbar-nav main-nav align-items-start ms-auto">
      <li class="nav-item fw-bold text-link fw-700">
        <a class="nav-link link-primary" target="_blank" href="/about"> Our Mission </a>
      </li>
      <li class="nav-item fw-bold text-link fw-700">
        <a class="nav-link link-primary" target="_blank" href="/educators"> Educators </a>
      </li>
      <li class="nav-item">
        <a class="btn btn-primary text-link fw-900" target="_blank" href="/contact"> <fa-icon class="me-2" [icon]="['far', 'at']"></fa-icon> Contact Us </a>
      </li>
      <!-- If the user is not logged in show login button -->
      <div *ngIf="user === undefined" class="nav-item">
        <a class="btn btn-primary text-link fw-900" target="_blank" href="/auth/login"> <fa-icon class="me-2" [icon]="['far', 'atom']"></fa-icon> Log In </a>
      </div>
      <!--  TODO Inset user profile picture HERE -->
      <div *ngIf="user !== undefined" class="nav-item">
        <a class="btn btn-primary text-link fw-900" target="_blank" href="/auth/login"> <fa-icon class="me-2" [icon]="['fad', 'flask']"></fa-icon> Portal </a>
      </div>
    </ul>
  </div>
</nav>
<main class="mb-4">
  <div class="header mb-4">
    <section class="container">
      <div class="header-text">Register For a Training</div>
    </section>
  </div>
  <div class="container pb-3">
    <p class="text-dark text-center px-5" style="font-size: larger">
      Welcome Science Teachers! You have access to all of our resources at no additional cost to your district. Please register for a training below to get access to you account.
    </p>
    <div class="grid">
      <div class="info-box">
        <div>
          <h1 class="text-primary">New Teacher Orientation</h1>
          <p class="text-dark text-center">
            You have access to a bunch of cool new science resources! Yay! During this new teacher orientation session, you will get an overview of our resources, time to explore
            your teacher portal at your own pace, and tips for successful implementation. Our National Science Foundation award winning resources are proven to significantly
            increase students’ learning, motivation, confidence, and STEM career interest! Let’s Power Up your lessons with Plasma Games!
          </p>
        </div>
        <a target="_blank" href="https://zoom.us/webinar/register/WN_0LGEeuJcQBmp-6eCv6YOFQ" class="img-hover">
          <img class="img-fluid px-5" src="assets/img/secret-page/secret-page-btn.png" alt="" />
        </a>
      </div>

      <div class="info-box">
        <div>
          <h1 class="text-primary">Refresher Course</h1>
          <p class="text-dark text-center">
            Time to upgrade you to a Sci-Ops expert! Attend this abbreviated training session to see all the new exciting changes we’ve made over the summer - including a new and
            improved teacher and student portal! We’ve listened to your feedback and we can’t wait for you to see how we’ve implemented it.
          </p>
        </div>
        <a target="_blank" href="https://zoom.us/webinar/register/WN_OabOVJULR_K5v_KJbkktHA" class="img-hover">
          <img class="img-fluid px-5" src="assets/img/secret-page/secret-page-btn.png" alt="" />
        </a>
      </div>

      <div class="info-box">
        <div>
          <h1 class="text-primary">District Staff Training</h1>
          <p class="text-dark text-center">
            We are excited to be working with you and your teachers this school year! During this district staff training session, our curriculum team will give you an overview of
            our resources, access to your district portal in order to track teacher usage, and give you tips for successful implementation in your district. Our National Science
            Foundation award winning resources are proven to significantly increase students’ learning, motivation, confidence, and STEM career interest! Let’s Power Up your
            district with Plasma Games!
          </p>
        </div>
        <a target="_blank" href="https://zoom.us/webinar/register/WN_JhAo5xXTTJ-fmZMW1F4Bmg" class="img-hover">
          <img class="img-fluid px-5" src="assets/img/secret-page/secret-page-btn.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</main>
<footer>
  <div class="footer-divider"></div>
  <div class="footer-content">
    <a target="_blank" href="/legal" class="footer-title-text text-white">EUALA & Privacy</a>
    <a target="_blank" href="/contact" class="footer-title-text text-white">Contact Us</a>
    <div>
      <div class="footer-title-text">Check Us Out On</div>
      <div class="footer-icon-container">
        <a class="lift" href="https://www.facebook.com/PlayPlasmaGames/" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'facebook-square']"></fa-icon>
        </a>
        <a class="lift" href="https://twitter.com/playplasmagames" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'twitter-square']"></fa-icon>
        </a>
        <a class="lift" href="https://www.linkedin.com/company/plasma-games/" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'linkedin']"></fa-icon>
        </a>
        <a class="lift" href="https://www.instagram.com/playplasmagames" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'instagram-square']"></fa-icon>
        </a>
        <a class="lift" href="https://www.youtube.com/channel/UC7qesg-ffGwv9FkTOoR8uaQ" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'youtube-square']"></fa-icon>
        </a>
        <a class="lift" href="https://www.pinterest.com/playplasmagames" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'pinterest-square']"></fa-icon>
        </a>
        <a class="lift" href="https://discord.gg/RAyXa25" target="_blank">
          <fa-icon class="fa-2x" [icon]="['fab', 'discord']"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</footer>
